import { LitElement, PropertyValueMap, html, nothing } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { customElement, property, query, state } from "lit/decorators.js";
import { globalStyles } from "./styles";

export type AccountSummary = { text: string; displayName: string; avatar: string };

export function get(url: string) {
    if (location.href.includes("localhost")) {
        url = "http://localhost:3333" + url;
    }
    return fetch(url);
}

@customElement("sumup-app")
export class App extends LitElement {
    static styles = [globalStyles];

    @state()
    message?: string;

    @state()
    error = false;

    @state()
    avatar?: string;

    @state()
    displayName?: string;

    @query("#input")
    input?: HTMLInputElement;

    @query("#writingStyle")
    styleInput?: HTMLInputElement;

    @query("#load")
    load?: HTMLInputElement;

    loading = false;

    account: string | null;
    type: string = "funny";
    writingStyle: string = "";

    @state()
    copiedToClipboard = false;

    constructor() {
        super();
        this.account = new URL(window.location.href).searchParams.get("account");
        this.writingStyle = new URL(window.location.href).searchParams.get("style") ?? "";
    }

    protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        if (this.account) this.hitMe();
    }

    render() {
        return html` <div class="flex flex-col items-center max-w-[600px] m-auto">
            <a class="text-center text-primary font-bold text-2xl my-4" href="/">Sum-up</a>
            <div class="text-center mb-4">See what ChatGPT thinks of your Bluesky feed<br />Summaries are updated once a day</div>
            <div class="flex flex-col gap-2 w-full">
                <label class="text-center">Summarize Bluesky user's</label>
                <input
                    id="input"
                    class="flex-1 border rounded-l px-2 py-1 w-full"
                    placeholder="E.g. badlogic.bsky.social"
                    value="${this.account || nothing}"
                />
                <label class="text-center">last 50 posts in the style of</label>
                <input
                    id="writingStyle"
                    class="border rounded px-2 py-1 w-full"
                    value=${this.writingStyle}
                    placeholder="e.g. Donald Duck, but you can leave it empty"
                />
                <button id="load" class="bg-primary text-white rounded px-4 m-auto" @click=${() => this.hitMe()}>Hit me</button>
            </div>
            ${this.loading
                ? html`<div class="mt-4 text-center m-auto">
                          Asking ChatGPT to tell you how swell you are. <br />
                          This could take a while. Look at the cat.
                      </div>
                      <img class="w-[100px]" src="cat-nyan-cat.gif" />`
                : nothing}
            ${this.avatar ? html`<img class="my-4 rounded-full max-w-[150px] max-h-[150px]" src="${this.avatar}" />` : nothing}
            ${this.displayName
                ? html`<div class="text-bold text-2xl">${this.displayName}</div>
                      <div class="mb-4 font-bold text-primary text-center cursor-pointer" @click=${() => this.copyToClipboard(location.href)}>
                          ${this.copiedToClipboard ? "Copied link to clipboard" : "Share"}
                      </div>`
                : nothing}
            ${this.message
                ? html`<div class="align-center text-center font-bold italic mb-4 px-4">
                          NOTE: this is a summary generated by ChatGPT. ChatGPT is prone to errors and may misrepresent what the user is actually
                          saying. This is an experiment to demonstrate that AI can be both good and extremely terrible. Take it with a huge grain of
                          salt.
                      </div>
                      <div class="px-4">${unsafeHTML(this.message)}</div>`
                : nothing}
            ${this.error
                ? html`<div class="text-center italic my-4">
                      Whoopsies, something went wrong. <br />
                      Either the account doesn't exist, is private, has no posts, or ChatGPT fell on its face. Check the account name or try again
                      later.
                  </div>`
                : nothing}
            <div class="flex-1"></div>
            <div class="text-center italic my-4">
                Lovingly made by <a class="text-primary" href="https://bsky.app/profile/badlogic.bsky.social">Mario Zechner</a><br />
                No data is collected, not even your IP address.
                <a class="text-primary" href="https://github.com/badlogic/sum-up"><br />Source code</a>
            </div>
        </div>`;
    }

    async hitMe() {
        this.avatar = undefined;
        this.displayName = undefined;
        this.message = undefined;
        this.error = false;
        this.loading = true;

        this.input!.disabled = true;
        this.styleInput!.disabled = true;
        this.load!.disabled = true;

        this.requestUpdate();

        const account = this.input!.value.trim();
        if (account.length == 0) {
            this.input!.disabled = false;
            this.styleInput!.disabled = false;
            this.load!.disabled = false;
            return;
        }
        this.type = "funny";
        this.writingStyle = this.styleInput?.value.trim() ?? "";

        try {
            const response = await get(`/api/summarize/${account}?type=${this.type}&style=${this.writingStyle}`);
            if (response.status != 200) {
                this.error = true;
            } else {
                const reply = (await response.json()) as {
                    accountSummary: { text: string; displayName: string; avatar: string };
                    gptSummary: string;
                };
                this.avatar = reply.accountSummary.avatar;
                this.displayName = reply.accountSummary.displayName;
                this.message = reply.gptSummary.replace("Summary:", "").trim().replaceAll("\n", "<br>");
                const url = new URL(window.location.href);
                url.searchParams.set("account", account);
                url.searchParams.set("type", this.type ?? "funny");
                url.searchParams.set("style", this.writingStyle ?? "");
                history.replaceState(null, "", url.toString());
            }
        } catch (e) {
            this.error = true;
        }
        this.input!.disabled = false;
        this.styleInput!.disabled = false;
        this.load!.disabled = false;
        this.loading = false;
    }

    copyToClipboard(text: string) {
        const input = document.createElement("input");
        input.value = text;

        document.body.appendChild(input);
        input.select();

        try {
            document.execCommand("copy");
            this.copiedToClipboard = true;
        } catch (err) {
            this.copiedToClipboard = false;
        } finally {
            document.body.removeChild(input);
        }
    }
}

@customElement("radio-button-group")
class RadioButtonGroup extends LitElement {
    @property()
    selectedValue = "funny";
    @property()
    options = ["funny", "serious"];
    @property()
    disabled = false;

    static styles = [globalStyles];

    render() {
        return html`<div class="flex gap-2 mt-4">
            ${this.options.map(
                (option) => html`
                    <label>
                        <input
                            type="radio"
                            name="radioGroup"
                            .value=${option}
                            .checked=${this.selectedValue === option}
                            @change=${this.handleRadioChange}
                            ${this.disabled ? "disabled" : ""}
                        />
                        ${this.capitalizeFirstLetter(option)}
                    </label>
                `
            )}
        </div>`;
    }

    capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    handleRadioChange(e: Event) {
        const selectedValue = (e.target as HTMLInputElement).value;
        this.selectedValue = selectedValue;
        this.dispatchEvent(
            new CustomEvent("change", {
                detail: {
                    value: selectedValue,
                },
            })
        );
    }
}
